import { App } from "@pimo/pimo-app-builder";
import { APP_ROUTES } from "../constants";

import { formatDate } from "dlp-utils";
import type { DLPAppState } from "../app";
import { DLPOverlay } from "../components/overlay/dlp-overlay";
import {
  fetchOEProjects,
  fetchOeProjectOverview,
  fetchProgram,
  updateProgramReportingDate,
} from "./dlp-fetch-helper";
import { isUserAdmin } from "./is-user-admin";

export const createOverlay = (app: App<DLPAppState>) => {
  const view = app.createOverlayView({ name: "overlay" });
  const overlay = view.addComponent({ component: DLPOverlay });

  overlay.on("overlay:toggle-sidebar", () => {
    const state = app.getAppState();

    app.setAppState({
      ...state,
      isSidebarOpen: !state.isSidebarOpen,
    });
  });

  overlay.on("overlay:click-logo", async () => {
    if (isUserAdmin(app.getAppState().userProfile)) {
      app.navigate(APP_ROUTES.dashboard);
    } else {
      const oeProjects = await fetchOEProjects();
      app.navigate(`/reports/${oeProjects?.[0]?.id ?? 0}/dashboard`);
    }
  });

  overlay.on("overlay:toggle-date-picker", async ({ payload }) => {
    if (!payload) {
      return;
    }

    const datePickerPayload = payload as { reportingDate: string };

    await updateProgramReportingDate(
      formatDate(new Date(datePickerPayload?.reportingDate ?? ""))
    );

    const [oeProjectOverview, program] = await Promise.all([
      fetchOeProjectOverview(),
      fetchProgram(),
    ]);

    const state: DLPAppState = app.getAppState();

    app.setAppState({
      ...state,
      oeProjectOverview,
      program,
    });
  });

  overlay.on("overlay:download-report", () => {
    app.navigate("/pdf", { state: { print: true } });
  });

  overlay.mapState(
    ({ isSidebarOpen, program, sideMenuEntries, userProfile }) => ({
      hasSearchFunctionality: true,
      header: {
        logo: {
          big: "allianz.svg",
          small: "allianz-small.svg",
        },
        username: `${userProfile?.name ?? "n/a"} (${
          userProfile?.email ?? "n/a"
        })`,
      },
      menuEntries: [
        {
          icon: "dashboard.svg",
          level: 0,
          link: APP_ROUTES.dashboard,
          title: "DLP Dashboard",
          onlyForAdmin: true,
        },
        {
          icon: "clipboard.svg",
          level: 0,
          link: APP_ROUTES.overview,
          title: "DLP Overview",
          onlyForAdmin: false,
        },
        {
          icon: "barchart.svg",
          items:
            sideMenuEntries
              ?.sort((projectA, projectB) =>
                projectA.name.localeCompare(projectB.name)
              )
              .map(({ id, name }) => ({
                level: 1,
                link: `/reports/${id ?? 0}/dashboard`,
                title: name,
                active: window.location.pathname.includes(
                  `/reports/${id ?? 0}/dashboard`
                ),
              })) ?? [],
          level: 0,
          title: "OE List",
        },
      ].filter(
        ({ onlyForAdmin }) =>
          !onlyForAdmin ||
          isUserAdmin(userProfile) ||
          userProfile?.regionsUserHasAccessTo?.length
      ),
      reportingDate: program?.reportingDate,
      searchFunction: (items, query) => {
        if (!items?.length) {
          return [];
        }

        const lowerCaseQuery = query?.toLowerCase();

        return (
          sideMenuEntries
            ?.filter(
              ({ name, region }) =>
                name?.toLowerCase().includes(lowerCaseQuery) ||
                region?.toLowerCase()?.includes(lowerCaseQuery)
            )
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map(({ id, name }) => ({
              level: 1,
              link: `/reports/${id ?? 0}/dashboard`,
              title: name,
              active: window.location.pathname.includes(`/reports/${id ?? 0}`),
            })) ?? []
        );
      },
      isSidebarOpen,
      userProfile,
    })
  );

  return overlay;
};
